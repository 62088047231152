$(() => {
    $(".fetch-rb-tags").on("keypress", "input", function (e) {
        if (e.which == 13) {
            e.preventDefault();
            //$("#signupModal").modal("show");
        }
    });

    $(".fetch-rb-tags").on("submit", function (e) {
        e.preventDefault();

        var keyword = $(this).find("input[name=keyword]").val();
        var platform = $(this).find("input[name=platform]").val();
        var sort = $(this).find("select[name=sort_by]").val();
        var button = $(this).find("button[id=send-btn]");

        $("#export-btn").hide();
        var input = document.getElementById("generated_tags");

        if (keyword.length == 0) {
            button.html("Please input a valid keyword");
            return;
        }

        button.html("Loading..");
        button.attr("disabled", true);
        input.innerHTML = "";

        $(".content-area").hide();

        $.ajax({
            url: "/tags",
            type: "POST",
            data: {
                keyword: keyword.toLowerCase(),
                platform: platform,
                etsy_sort_by: sort,
            },
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (data) {
                var tags = getKeywordsCloud(JSON.parse(data));

                input.innerHTML =
                    '<table class="tag-list"><thead><tr><th>Tag</th></tr></thead><tbody>' +
                    tags +
                    "</tbody></table>";

                $(".content-area").show();
                $(".content-area .format-tags").show();
                $(".content-area .sub-title").show();
            },
            error: function (xhr, status, error) {
                $(".content-area").show();
                $(".content-area .format-tags").hide();
                $(".content-area .sub-title").hide();
                input.innerHTML = xhr.responseText + ".";
            },
        }).always(function () {
            button.html('<i class="fa fa-search"></i> Get Tags');
            button.attr("disabled", false);
        });
    });

    // This must be a hyperlink
    $(document).on("click", "#export-btn", function (event) {
        var data = [];
        var date = new Date();
        data.push(["keywords"]);
        data.push([window.customData]);
        exportToCSV(
            "export_" +
                date.getFullYear() +
                "_" +
                (date.getMonth() + 1) +
                "_" +
                date.getDate() +
                ".csv",
            data
        );
    });

    $(".unselectable")
        .attr("unselectable", "on")
        .css({
            "-moz-user-select": "-moz-none",
            "-moz-user-select": "none",
            "-o-user-select": "none",
            "-khtml-user-select":
                "none" /* you could also put this in a class */,
            "-webkit-user-select":
                "none" /* and add the CSS class here instead */,
            "-ms-user-select": "none",
            "user-select": "none",
        })
        .bind("selectstart", function () {
            return false;
        });
});

function getUniqueKeywords(list) {
    const keywordCounts = list.reduce((acc, keyword) => {
        acc[keyword] = (acc[keyword] || 0) + 1;
        return acc;
    }, {});

    return Object.entries(keywordCounts)
        .map(([title, rank]) => ({ title, rank }))
        .filter(({ rank }) => rank >= 1)
        .sort((a, b) => b.rank - a.rank);
}

function buildKeywordRow(keyword) {
    const { title, rank } = keyword;
    return ` <tr class="related-keyword"><td>${title}</td></tr>`;
}

function getKeywordsCloud(list) {
    const uniqueKeywords = getUniqueKeywords(list);
    let relatedKeywords = uniqueKeywords.map(buildKeywordRow).join("");

    if (relatedKeywords === "") {
        relatedKeywords =
            ' <tr class="related-keyword">Not enough density to parse related keywords.</tr> ';
    }

    return relatedKeywords;
}

// Function to get all the tags and return them as an array
function getTags() {
    const arr = [];
    $(".tag-list tbody tr").each(function () {
        arr.push($(this).find("td:first").text());
    });
    return arr;
}

function createFormattedTagDiv(tagList) {
    return $('<div class="formated-list-of-tags"></div>').text(tagList);
}

function createHiddenInput(tagList) {
    return $(
        `<input type="text" id="copyTags" style="display: none" value="${tagList}">`
    );
}

function copyToClipboard(textElementId) {
    const copyText = document.getElementById(textElementId);
    const textToCopy = copyText.value;

    navigator.clipboard
        .writeText(textToCopy)
        .then(function () {
            alert("Copied: " + textToCopy);
        })
        .catch(function (error) {
            console.error("Error in copying text: ", error);
        });
}

$(document).on("click", ".format-tags", function (e) {
    $(".formated-list-of-tags").remove();
    $("#copyTags").remove();

    const tags = getTags();
    const tagList = tags.join(",");

    const formattedTagDiv = createFormattedTagDiv(tagList);
    const hiddenInput = createHiddenInput(tagList);

    $(".tag-list").before(formattedTagDiv);
    $(".tag-list").before(hiddenInput);

    copyToClipboard("copyTags");
});
